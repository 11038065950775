import { Vedlegg as VedleggComponent } from "@utdanningsdirektoratet/vedlegg";
import { FormattedMessage } from "react-intl";
import { VedleggViewModel } from "../../../../ApiClients";

const Vedlegg = ({
  vedlegg,
  notatVedlegg,
}: {
  vedlegg: Array<VedleggViewModel>;
  notatVedlegg: Array<VedleggViewModel>;
}) => {
  if (notatVedlegg.length === 0 && vedlegg.length === 0) return null;
  const notatVedleggList = notatVedlegg.map((v) => {
    return (
      <VedleggComponent
        key={`notatVedlegg-${v.id}`}
        className="u--marginBottom0-5"
        title={v.filNavn}
        href={v.url}
        disabled
      />
    );
  });
  const vedleggList = vedlegg.map((v) => {
    return (
      <VedleggComponent
        key={`vedlegg-${v.id}`}
        className="u--marginBottom0-5"
        title={v.filNavn}
        href={v.url}
        disabled
      />
    );
  });

  return (
    <div className="Page-section u--marginBottom2">
      <div className="u--label">
        <FormattedMessage id="formLabels.vedlegg" />
      </div>
      {notatVedleggList}
      {vedleggList}
    </div>
  );
};

export default Vedlegg;
