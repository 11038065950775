import { CurrencyInput } from "@utdanningsdirektoratet/maskedinput";
import { FormattedMessage } from "react-intl";
import { useForm } from "@utdanningsdirektoratet/form";
import { isEmpty } from "@utdanningsdirektoratet/utils/string";
import Obligatorisk from "./Obligatorisk";
import { gyldigHetall } from "../../../../../logic/validation";
import {
  getSpørsmålFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";
import { SpørsmålUttalelseViewModel } from "../../../../../ApiClients";

const HeltallSvar = ({
  id,
  readonly,
  obligatorisk,
  forhandsvisning,
}: {
  id: number;
  readonly: boolean;
  obligatorisk: boolean;
  forhandsvisning: boolean;
}) => {
  const { fields = {} } =
    useForm<Record<SpørsmålFormId, SpørsmålUttalelseViewModel["heltall"]>>();

  const field = fields[getSpørsmålFormId(id, "heltall")] ?? {};

  if (!forhandsvisning && readonly) {
    if (field.value) {
      return <div className="Sporsmal-body">{field.value}</div>;
    }
    return (
      <div className="Sporsmal-body Sporsmal-body--italic">
        <FormattedMessage id="validation.ikkeAngittHeltall" />
      </div>
    );
  }

  return (
    <div className="Sporsmal-body">
      <Obligatorisk isObligatorisk={obligatorisk}>
        <CurrencyInput
          {...field}
          aria-label="Heltall"
          className="Sporsmal-input Sporsmal-heltall"
          disabled={readonly}
          value={`${field.value}`}
          onChange={(e) => {
            if (gyldigHetall(parseInt(e, 10)) && e !== `${field.value}`) {
              field.onChange(isEmpty(e) ? undefined : parseInt(e, 10));
            }
          }}
          onBlur={(e) => {
            if (gyldigHetall(parseInt(e, 10)) && e !== `${field.value}`) {
              field.onBlur(isEmpty(e) ? undefined : parseInt(e, 10));
            }
          }}
          integerLimit={10}
          allowDecimal={false}
          allowNegative={false}
        />
      </Obligatorisk>
    </div>
  );
};

export default HeltallSvar;
