import { FormattedMessage } from "react-intl";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { institusjonNavnLang } from "../../../../logic/initialState";
import { DATE_FORMAT } from "../../../../constants/dateFormats";

const Header = ({
  avsender,
  frist,
  publisert,
  referanse,
}: {
  avsender?: string | null | undefined;
  frist?: Date | null | undefined;
  publisert?: Date | null | undefined;
  referanse?: string | null | undefined;
}) => {
  return (
    <div className="Header">
      <div className="Grid-12-12">
        <div aria-label={institusjonNavnLang} className="Header-logo" />
      </div>
      <div className="Header-content Grid-xl-6-12 Grid-12-12">
        {avsender ? (
          <span>
            <div className="Header-title">
              <FormattedMessage id="header.varSaksbehandler" />
            </div>
            {avsender}
          </span>
        ) : null}
      </div>
      <div className="Grid-xl-6-12 Grid-12-12">
        <div className="Header-content Grid-4-12">
          {frist ? (
            <span>
              <div className="Header-title">
                <FormattedMessage id="header.fristForUttalelse" />
              </div>
              {formatDate(frist, DATE_FORMAT)}
            </span>
          ) : null}
        </div>
        <div className="Header-content Grid-4-12">
          {publisert ? (
            <span>
              <div className="Header-title">
                <FormattedMessage id="header.publisertDato" />
              </div>
              {formatDate(publisert, DATE_FORMAT)}
            </span>
          ) : null}
        </div>
        <div className="Header-content Grid-4-12">
          {referanse && referanse !== "eInnsyn" ? (
            <span>
              <div className="Header-title">
                <FormattedMessage id="header.varReferanse" />
              </div>
              {referanse}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
