import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Vedlegg as VedleggComponent } from "@utdanningsdirektoratet/vedlegg";
import { Popin, show, close, usePopin } from "@utdanningsdirektoratet/popin";
import { FileInput } from "@utdanningsdirektoratet/fileinput";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { useForm } from "@utdanningsdirektoratet/form";
import Obligatorisk from "./Obligatorisk";
import {
  HoeringClient,
  SpørsmålUttalelseVedleggViewModel,
  SpørsmålUttalelseViewModel,
} from "../../../../../ApiClients";
import { urls } from "../../../../../logic/initialState";
import {
  getSpørsmålFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";
import { useUttalelse } from "../../../../../hooks";

const client = new HoeringClient();

const getFormData = (files: Array<File>) => {
  return files.map((file) => ({ fileName: file.name, data: file }));
};

const Vedlegg = ({
  id,
  readonly,
  obligatorisk,
  forhandsvisning,
  editManueltRegistert,
  autosave,
}: {
  id: number;
  readonly: boolean;
  obligatorisk: boolean;
  forhandsvisning: boolean;
  editManueltRegistert: boolean;
  autosave: (immediate?: boolean) => Promise<string>;
}) => {
  const [selected, setSelected] = useState<SpørsmålUttalelseVedleggViewModel>();
  const intl = useIntl();
  const { mutate } = useUttalelse();
  const { fields = {} } =
    useForm<Record<SpørsmålFormId, SpørsmålUttalelseViewModel["vedlegg"]>>();

  const field = fields[getSpørsmålFormId(id, "vedlegg")] ?? {};

  const [popinId] = usePopin();
  const uploadFunc = async (files: Array<File>) => {
    const eksternId = await autosave(true);
    try {
      const body = getFormData(files);

      const result = await client.lastOppSporsmaalUttalelseVedlegg(
        body,
        id,
        eksternId,
      );
      if (result.errors !== "") {
        newNotification(
          "error",
          intl.formatMessage({ id: "validation.opplastingFerdigMedFeil" }),
          intl.formatMessage(
            { id: "validation.opplastingFerdigMedFeilMessage" },
            { 0: result.errors },
          ),
        );
      } else {
        newNotification(
          "success",
          intl.formatMessage({ id: "validation.opplastingFerdig" }),
          "",
          true,
        );
      }

      if (editManueltRegistert) {
        await mutate();
      } else {
        field.onChange([...field.value, ...result.files]);
      }
    } catch {
      newNotification(
        "error",
        intl.formatMessage({ id: "validation.opplastingFerdigMedFeil" }),
        intl.formatMessage(
          { id: "validation.opplastingFerdigMedFeilMessage" },
          { 0: files.map((file) => file.name).join(", ") },
        ),
      );
    }
  };

  const showPopin = (_eksternId: string) => {
    const _selected = field.value.find((v) => v.eksternId === _eksternId);
    if (_selected) {
      setSelected(_selected);
      show(popinId);
    }
  };

  const deleteFuncWrapped = async () => {
    if (selected?.eksternId) {
      close(popinId);

      try {
        const result = await client.slettSporsmaalUttalelseVedlegg(
          selected.eksternId,
        );
        newNotification(
          "success",
          intl.formatMessage({ id: "validation.vedleggSlettet" }),
          "",
          true,
        );
        field.onChange(
          field.value.filter((v) => v.eksternId !== result.eksternId),
        );
      } catch {
        newNotification(
          "error",
          intl.formatMessage({ id: "validation.slettingAvVedleggMislyktes" }),
        );
      }
    }
  };

  const vedleggs = field.value.map((v) => {
    return (
      <VedleggComponent
        key={`vedlegg-${v.eksternId}`}
        title={v.filNavn}
        href={`${urls.lastNedSpørsmålUttalelseVedlegg}/${v.eksternId}`}
        onDelete={() => showPopin(v.eksternId)}
        disabled={readonly}
        className="Vedlegg--uploaded u--marginBottom1"
      />
    );
  });

  const label = (
    <span>
      <Icon icon="document" type="small" placement="before" />
      <FormattedMessage id="formLabels.leggTilVedlegg" />
    </span>
  );

  const selectedVedleggFilename = selected?.filNavn;

  if (!forhandsvisning && readonly) {
    if (vedleggs.length === 0) {
      return (
        <div className="Sporsmal-body Sporsmal-body--italic">
          <FormattedMessage id="validation.ingenVedlegg" />
        </div>
      );
    }
  }

  const buttonClass = cls({
    "Sporsmal-button--error": field.error,
    "Sporsmal-button-vedlegg": true,
  });

  let button = null;
  if (forhandsvisning || !readonly) {
    button = (
      <Obligatorisk isObligatorisk={obligatorisk}>
        <FileInput
          {...field}
          className={buttonClass}
          onChange={uploadFunc}
          disabled={readonly}
          label={label}
          multiple
        />
      </Obligatorisk>
    );
  }

  return (
    <>
      <div className="Sporsmal-body">
        {vedleggs.length > 0 ? (
          <div className="u--label">
            <FormattedMessage id="formLabels.vedlegg" />
          </div>
        ) : null}
        <div className="Vedlegg-container">{vedleggs}</div>
        {button}
      </div>
      <Popin
        id={popinId}
        title={<FormattedMessage id="formLabels.slettVedlegg" />}
        closeableByExternalEvents
        saveButton={<FormattedMessage id="formLabels.slett" />}
        onSave={deleteFuncWrapped}
        cancelButton={<FormattedMessage id="formLabels.avbryt" />}
      >
        <FormattedMessage
          id="formLabels.slettVedleggTekst"
          values={{ 0: selectedVedleggFilename }}
        />
      </Popin>
    </>
  );
};

export default Vedlegg;
