import { Icon, SmallIcon } from "@utdanningsdirektoratet/icon";
import { Button } from "@utdanningsdirektoratet/button";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { FormattedMessage } from "react-intl";
import { useForm } from "@utdanningsdirektoratet/form";
import Obligatorisk from "./Obligatorisk";
import { JaNei, SpørsmålUttalelseViewModel } from "../../../../../ApiClients";
import {
  getSpørsmålFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";

const JaNeiSvar = ({
  id,
  readonly,
  obligatorisk,
  forhandsvisning,
}: {
  id: number;
  readonly: boolean;
  obligatorisk: boolean;
  forhandsvisning: boolean;
}) => {
  const { fields = {} } =
    useForm<Record<SpørsmålFormId, SpørsmålUttalelseViewModel["jaNei"]>>();

  const field = fields[getSpørsmålFormId(id, "jaNei")] ?? {};

  if (!forhandsvisning && readonly) {
    let svar: React.ReactNode = field.value;
    if (svar === JaNei.IkkeAngitt) return null;
    if (svar === JaNei.VetIkke)
      svar = <FormattedMessage id="formLabels.vetIkke" />;
    if (svar === JaNei.Ja) {
      svar = (
        <span>
          <Icon icon="check" type="small" placement="before" />
          <FormattedMessage id="formLabels.ja" />
        </span>
      );
    }
    if (svar === JaNei.Nei) {
      svar = (
        <span>
          <Icon icon="cross" type="small" placement="before" />
          <FormattedMessage id="formLabels.nei" />
        </span>
      );
    }
    return <div className="Sporsmal-body Sporsmal-body--bold">{svar}</div>;
  }

  const buttonClass = cls({
    "Sporsmal-button": true,
    "Sporsmal-button--error": field.error,
  });
  const button = (val: JaNei, icon: SmallIcon | null, txt: React.ReactNode) => {
    const iconElement = icon ? (
      <Icon icon={icon} type="small" placement="before" />
    ) : null;
    const active = field.value === val;
    return (
      <Button
        className={buttonClass}
        active={active}
        disabled={readonly}
        onClick={() => field.onChange(active ? JaNei.IkkeAngitt : val)}
      >
        {iconElement}
        {txt}
      </Button>
    );
  };

  return (
    <div className="Sporsmal-body">
      <Tooltip
        className="u--block"
        message={field.message}
        position="top"
        align="left"
        disabled={!field.error}
        alwaysShow
      >
        <Obligatorisk isObligatorisk={obligatorisk}>
          {button(JaNei.Ja, "check", <FormattedMessage id="formLabels.ja" />)}
          {button(JaNei.Nei, "cross", <FormattedMessage id="formLabels.nei" />)}
          {button(
            JaNei.VetIkke,
            null,
            <FormattedMessage id="formLabels.vetIkke" />,
          )}
        </Obligatorisk>
      </Tooltip>
    </div>
  );
};

export default JaNeiSvar;
