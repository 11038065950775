import { SetURLSearchParams } from "react-router-dom";
import { Fields } from "@utdanningsdirektoratet/form";
import {
  scrollTo,
  scrollToHeader,
  scrollToId,
  ScrollType,
} from "./scrollUtils";
import { HøringViewModel } from "../ApiClients";
import { NOTAT_ID } from "../constants/queryParam";
import {
  NotatFormId,
  notatPrefix,
  NotatSvarMulighetTypes,
  sporsmalPrefix,
  SporsmalSvarMulighetTypes,
  SpørsmålFormId,
} from "./hoeringUtils";

export const navigateToNotat = (
  [searchParams, setSearchParams]: [URLSearchParams, SetURLSearchParams],
  notatId: number,
  scrollToNotat: boolean | string = false,
) => {
  searchParams.set(NOTAT_ID, `${notatId}`);
  setSearchParams(searchParams);
  if (scrollToNotat === true) {
    scrollTo(scrollToId(ScrollType.NOTAT, notatId));
  } else if (scrollToNotat) {
    scrollTo(scrollToNotat);
  } else {
    scrollToHeader();
  }
};

const navigateToSporsmal = (
  [searchParams, setSearchParams]: [URLSearchParams, SetURLSearchParams],
  hoering: HøringViewModel,
  sporsmalId: number,
) => {
  const notat = hoering.notater.find((n) =>
    n.seksjoner.some((s) => s.spørsmål.some((spm) => spm.id === sporsmalId)),
  );
  navigateToNotat(
    [searchParams, setSearchParams],
    notat!.id,
    scrollToId(ScrollType.SPORSMAL, sporsmalId),
  );
};

export const navigateToFootnote =
  (footNoteId: string, notatId: number) => () => {
    window.location.hash = `#fotnote-${notatId}-${footNoteId}`;
  };

const firstInvalidId = (
  fields: Fields<Record<NotatFormId | SpørsmålFormId, unknown>>,
) => {
  const split =
    (Object.keys(fields) as Array<keyof typeof fields>)
      .filter((field) => fields[field].error)
      .map(
        (f) =>
          f.split("-") as [
            typeof notatPrefix | typeof sporsmalPrefix,
            string,
            NotatSvarMulighetTypes | SporsmalSvarMulighetTypes,
          ],
      ) ?? [];
  return {
    spørsmål: (split.find((s) => s[0] === sporsmalPrefix) ?? [])[1],
    notat: (split.find((s) => s[0] === notatPrefix) ?? [])[1],
  };
};

export const navigateToInvalid = (
  searchParams: [URLSearchParams, SetURLSearchParams],
  hoering: HøringViewModel,
  fields: Fields<Record<string, unknown>>,
) => {
  const invalid = firstInvalidId(fields);

  if (invalid.spørsmål) {
    navigateToSporsmal(searchParams, hoering, +invalid.spørsmål);
    return;
  }

  if (invalid.notat) {
    navigateToNotat(searchParams, +invalid.notat, true);
  }
};
