import { type AntiForgeryTokenInfo, type V2ViewModel } from "../ApiClients";

declare global {
  interface Window {
    initialState: V2ViewModel;
    epostRegex: RegExp;
  }
}

const initial = window.initialState;
export const urls = initial.hoeringUrls;
export const froala = initial.froalaLicense ?? "";
export const sentry = initial.sentryId ?? undefined;
export const matomoId = initial.matomoTagManager ?? undefined;
export const { texts, footer, personvernerklaering, features, version } =
  initial;

export const institusjonNavnLang = initial.institusjonNavnLang!;
export const getAntiForgeryTokenInfo = (): AntiForgeryTokenInfo =>
  window.initialState.antiForgeryTokenInfo!;
