import { DatePicker } from "@utdanningsdirektoratet/datepicker";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { FormattedMessage } from "react-intl";
import { useForm } from "@utdanningsdirektoratet/form";
import Obligatorisk from "./Obligatorisk";
import { DATE_FORMAT } from "../../../../../constants/dateFormats";
import {
  getSpørsmålFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";
import { SpørsmålUttalelseViewModel } from "../../../../../ApiClients";

const DatoSvar = ({
  id,
  readonly,
  obligatorisk,
  forhandsvisning,
}: {
  id: number;
  readonly: boolean;
  obligatorisk: boolean;
  forhandsvisning: boolean;
}) => {
  const { fields = {} } =
    useForm<Record<SpørsmålFormId, SpørsmålUttalelseViewModel["dato"]>>();

  const field = fields[getSpørsmålFormId(id, "dato")] ?? {};

  if (!forhandsvisning && readonly) {
    if (field.value) {
      return (
        <div className="Sporsmal-body">
          {formatDate(field.value, DATE_FORMAT)}
        </div>
      );
    }
    return (
      <div className="Sporsmal-body Sporsmal-body--italic">
        <FormattedMessage id="validation.ikkeAngittDato" />
      </div>
    );
  }
  return (
    <div className="Sporsmal-body">
      <Obligatorisk isObligatorisk={obligatorisk}>
        <DatePicker {...field} className="Sporsmal-dato" disabled={readonly} />
      </Obligatorisk>
    </div>
  );
};

export default DatoSvar;
