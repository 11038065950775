import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { institusjonNavnLang } from "../../logic/initialState";
import DocumentMetadata from "../../components/DocumentMetadata";
import HoeringsFooter from "../../components/Footer";

const Default = ({
  page,
}: {
  page?: "servererror" | "notfound" | "cookies";
}) => {
  const intl = useIntl();
  const [search] = useSearchParams();
  let content;

  switch (page) {
    case "servererror":
      content = (
        <h1 className="Page-title u-h1">
          <FormattedMessage id="error.detharOppstattEnFeil" />
        </h1>
      );
      break;
    case "notfound":
      content = (
        <h1 className="Page-title u-h1">
          <FormattedMessage id="error.sidenFinnesIkke" />
        </h1>
      );
      break;
    case "cookies":
      content = (
        <>
          <h1 className="Page-title u-h1">
            <FormattedMessage id="cookies.cookiesTittel" />
          </h1>

          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "cookies.cookiesTeknisk" }),
            }}
          />

          <h2 className="u-h2">
            <FormattedMessage id="cookies.cookiesMellomlagringOverskrift" />
          </h2>
          <p>
            <FormattedMessage id="cookies.cookiesMellomlagring" />
          </p>

          <h2 className="u-h2">
            <FormattedMessage id="cookies.cookiesStatistikkOverskrift" />
          </h2>
          <p>
            <FormattedMessage id="cookies.cookiesStatistikk" />
          </p>
        </>
      );
      break;
    default:
      content = (
        <h1 className="Page-title u-h1">
          <FormattedMessage id="default.forInformasjonOmHoeringerKlikkHer" />
        </h1>
      );
      break;
  }

  return (
    <>
      <DocumentMetadata title={institusjonNavnLang} />
      <div className="Grid">
        <div className="Page">
          <main id="main" className="Page-section u--marginTop4">
            {content}
          </main>
        </div>
      </div>
      <HoeringsFooter hoeringId={search.get("hoeringId")} />
    </>
  );
};

export default Default;
