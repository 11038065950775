import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Sektor } from "../../../../ApiClients";
import { urls } from "../../../../logic/initialState";
import { DATE_FORMAT } from "../../../../constants/dateFormats";
import { useUttalelse } from "../../../../hooks";
import { routes } from "../../../../routes";

const InnsendtHeader = ({ show = false }: { show?: boolean }) => {
  const { data: uttalelse } = useUttalelse();

  if (!show || !uttalelse) return null;

  const { eksternId, navnSkjult } = uttalelse;

  return (
    <div className="Header Header--innsendt">
      <div className="Header-buttons Grid-12-12">
        <LinkButton
          href={routes.hoering(uttalelse.høringId)}
          className="Header-button"
        >
          {(props) => {
            return (
              <Link {...props} to={props.href!}>
                <FormattedMessage id="innsendtUttalelse.nyUttalelse" />
              </Link>
            );
          }}
        </LinkButton>
        <LinkButton
          target="_blank"
          rel="noopener noreferrer"
          href={`${urls.lastNedUttalelseUrl}/${eksternId}`}
          className="Header-button"
        >
          <Icon icon="document" type="small" placement="before" />
          <FormattedMessage id="innsendtUttalelse.lastNedUttalelse" />
        </LinkButton>
      </div>
      <div className="Header-content Grid-12-12">
        <div className="Header-title">
          <FormattedMessage id="innsendtUttalelse.status" />
        </div>
        <div>
          <FormattedMessage id="innsendtUttalelse.innsendtTil" />{" "}
          <FormattedMessage id="innsendtUttalelse.innsendtTilInstitusjon" />
        </div>
        <div>{uttalelse.status}</div>
      </div>
      <div className="Grid-12-12 u--marginTop2">
        <div className="Header-content Grid-lg-3-12 Grid-6-12">
          <div className="Header-title">
            <FormattedMessage id="innsendtUttalelse.innsendtAv" />
          </div>
          {navnSkjult ? (
            <em>
              <FormattedMessage id="innsendtUttalelse.skjultAv" />
            </em>
          ) : (
            uttalelse.innsenderNavn
          )}
        </div>
        <div className="Header-content Grid-lg-3-12 Grid-6-12">
          <div className="Header-title">
            <FormattedMessage id="innsendtUttalelse.innsendtDato" />
          </div>
          {formatDate(uttalelse.mottatt ?? new Date(), DATE_FORMAT)}
        </div>
        <div className="Header-content Grid-lg-3-12 Grid-6-12">
          <div className="Header-title">
            {uttalelse.sektor === Sektor.Enkeltperson ? (
              uttalelse.innsenderRepresentererEtikett
            ) : (
              <FormattedMessage id="innsendtUttalelse.paVegneAv" />
            )}
          </div>
          {navnSkjult && uttalelse.sektor === Sektor.Enkeltperson ? (
            <em>
              <FormattedMessage id="innsendtUttalelse.skjultAv" />
            </em>
          ) : (
            uttalelse.påVegneAv
          )}
        </div>
        {uttalelse.malgruppe ? (
          <div className="Header-content Grid-lg-3-12 Grid-6-12">
            <div className="Header-title">
              <FormattedMessage id="innsendtUttalelse.malgruppe" />
            </div>
            {uttalelse.malgruppe}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InnsendtHeader;
