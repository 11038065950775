import "less/base.less";
import { createRoot } from "react-dom/client";
import * as React from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  Navigate,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import { sentry } from "./logic/initialState";
import App from "./App";
import StateProvider from "./StateProvider";
import Default from "./pages/default";
import Uttalelse from "./pages/uttalelse";
import { routes } from "./routes";

Sentry.init({
  dsn: sentry,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ["hflhss"],
      behaviour: "drop-error-if-contains-third-party-frames",
    }),
  ],
  tracesSampleRate: 0.1,
  normalizeDepth: 10,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  tunnel: "/tunnel",
  release: `hoering@${process.env.PACKAGE_VERSION}`,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <StateProvider>
        <BrowserRouter>
          <SentryRoutes>
            <Route element={<App />}>
              {[
                routes.hoering(":hoeringId"),
                routes.uttalelse(":hoeringId", ":eksternId"),
                routes.utkast(":hoeringId", ":eksternId"),
                routes.lesutkast(":hoeringId", ":leseTilgangsKode"),
              ].map((path) => (
                <Route key={path} path={path} element={<Uttalelse />} />
              ))}
              <Route path="/cookies" element={<Default page="cookies" />} />
              <Route index element={<Default />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </SentryRoutes>
        </BrowserRouter>
      </StateProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
