import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { FormattedMessage } from "react-intl";
import { useForm } from "@utdanningsdirektoratet/form";
import SvarAlternativ from "./SvarAlternativ";
import Obligatorisk from "./Obligatorisk";
import {
  EttFlereValgMulighetViewModel,
  SpørsmålUttalelseViewModel,
} from "../../../../../ApiClients";
import {
  getSpørsmålFormId,
  SpørsmålFormId,
} from "../../../../../utils/hoeringUtils";

const FlereValg = ({
  flereValg,
  id,
  readonly,
  obligatorisk,
  forhandsvisning,
}: {
  readonly: boolean;
  obligatorisk: boolean;
  forhandsvisning: boolean;
  id: number;
  flereValg: EttFlereValgMulighetViewModel;
}) => {
  const { svarAlternativ } = flereValg;

  const { fields = {} } =
    useForm<
      Record<
        SpørsmålFormId,
        SpørsmålUttalelseViewModel["svarPåSvarAlternativer"]
      >
    >();

  const field = fields[getSpørsmålFormId(id, "svarPåSvarAlternativer")] ?? {};

  const noSelection = field.value.every((spsa) => spsa.flereValg === false);
  if (!forhandsvisning && readonly && noSelection) {
    return (
      <div className="Sporsmal-body Sporsmal-body--bold">
        <div>
          <FormattedMessage id="validation.velgEttEllerFlereAlternativ" />
        </div>
        <em className="u--italic">
          <FormattedMessage id="validation.ingenAlternativerValgt" />
        </em>
      </div>
    );
  }

  const updateValue = (
    svarAlternativId: number,
    selected: boolean,
    egendefinert: string,
  ) => {
    const flereSvar = field.value.filter(
      (alt) => alt.svarAlternativId !== svarAlternativId,
    );
    field.onChange(
      selected
        ? [
            ...flereSvar,
            {
              flereValg: true,
              egendefinertSvar: egendefinert,
              svarAlternativId,
            },
          ]
        : flereSvar,
    );
  };

  const checkboxes = svarAlternativ.map((alternativ) => {
    const isChecked = field.value.find(
      (spsa) => spsa.svarAlternativId === alternativ.id,
    );
    const svar =
      isChecked?.egendefinertSvar &&
      field.value.find((spsa) => spsa.svarAlternativId === alternativ.id)
        ?.egendefinertSvar;

    return (
      <SvarAlternativ
        key={alternativ.id}
        id={alternativ.id}
        type="checkbox"
        egendefinertSvar={svar ?? ""}
        obligatorisk={obligatorisk}
        alternativ={alternativ}
        svarAlternativ={svarAlternativ}
        error={field.error}
        message={field.message}
        disabled={readonly}
        selected={!!isChecked}
        onChange={(selected, egendefinert) =>
          updateValue(alternativ.id, selected, egendefinert)
        }
      />
    );
  });

  const checkboxClass = cls({
    "u--marginTop1": checkboxes.length > 1,
  });

  return (
    <div className="Sporsmal-body">
      <Tooltip
        className="u--block"
        message={field.message}
        align="left"
        position="bottom"
        disabled={
          !field.error ||
          svarAlternativ
            .filter((sa) =>
              field.value.find((spsa) => spsa.svarAlternativId === sa.id),
            )
            .some((sa) => sa.erEgendefinert)
        }
        alwaysShow
      >
        {checkboxes.length > 1 ? (
          <Obligatorisk isObligatorisk={obligatorisk}>
            <FormattedMessage id="validation.velgEttEllerFlereAlternativ" />
          </Obligatorisk>
        ) : null}
        <div className={checkboxClass}>{checkboxes}</div>
      </Tooltip>
    </div>
  );
};

export default FlereValg;
