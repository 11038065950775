import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Button, LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { FormattedMessage, useIntl } from "react-intl";
import { show } from "@utdanningsdirektoratet/popin";
import { useSearchParams, Link } from "react-router-dom";
import { useForm } from "@utdanningsdirektoratet/form";
import { useEffect, useState } from "react";
import { HøringsNotatViewModel } from "../../../../ApiClients";
import { useHoering } from "../../../../hooks";
import {
  navigateToInvalid,
  navigateToNotat,
} from "../../../../utils/navigationUtils";
import { routes } from "../../../../routes";
import { useContextState } from "../../../../StateProvider";

const LagreOgSendInn = ({
  innsendt,
  notatHasSporsmals,
  forhandsvisning,
  hasFeedback,
  readOnly,
  nextNotat,
  currentNotat,
  sendInnKunSisteNotat,
  sendInnPopinId,
  lagreUtkastPopinId,
  videresendUtkastPopinId,
  autosave,
}: {
  innsendt: boolean;
  hasFeedback?: boolean;
  readOnly: boolean;
  forhandsvisning: boolean;
  nextNotat: HøringsNotatViewModel;
  currentNotat: HøringsNotatViewModel;
  sendInnKunSisteNotat?: boolean;
  notatHasSporsmals?: boolean;
  sendInnPopinId: string;
  lagreUtkastPopinId: string;
  videresendUtkastPopinId: string;
  autosave: (immediate?: boolean) => Promise<string>;
}) => {
  const intl = useIntl();
  const [, dispatch] = useContextState();
  const { data: høring } = useHoering();
  const { fields, isValid, validateFields } = useForm();
  const searchParams = useSearchParams();
  const [shouldNavigateToInvalid, setShouldNavigateToInvalid] = useState(false);

  useEffect(() => {
    if (shouldNavigateToInvalid && !isValid) {
      navigateToInvalid(searchParams, høring, fields);
      setShouldNavigateToInvalid(false);
    }
  }, [fields, høring, isValid, searchParams, shouldNavigateToInvalid]);

  if (innsendt || (readOnly && !forhandsvisning)) return null;

  const showLagreOgSendInn = async () => {
    await autosave(true);
    dispatch({ type: "SET_INGENMERKNADER", payload: false });

    if (!validateFields()) {
      show(sendInnPopinId);
    } else {
      setShouldNavigateToInvalid(true);
    }
  };

  const lagreOgGaVidere = async (
    currentNotatId: number,
    nextNotatId: number,
  ) => {
    await autosave(true);
    dispatch({ type: "SET_INGENMERKNADER", payload: false });

    if (
      !validateFields(
        (Object.keys(fields) as Array<keyof typeof fields>).filter(
          (field: string) =>
            field.indexOf(`${currentNotatId}`) !== -1 ||
            høring.notater
              .filter((n) => n.id === currentNotatId)
              .flatMap((n) => n.seksjoner)
              .flatMap((s) => s.spørsmål)
              .some((sp) => field.indexOf(`${sp.id}`) !== -1),
        ),
      )
    ) {
      navigateToNotat(searchParams, nextNotatId);
    } else {
      setShouldNavigateToInvalid(true);
    }
  };

  const showLagreUtkast = async () => {
    await autosave(true);
    show(lagreUtkastPopinId);
  };

  const showVideresendUtkast = async () => {
    await autosave(true);
    show(videresendUtkastPopinId);
  };

  let lagreButton = (
    <Button
      color="vy"
      className="Button--colored FooterButtons-button FooterButtons-submit"
      disabled={!hasFeedback || readOnly}
      onClick={showLagreOgSendInn}
    >
      <FormattedMessage id="formLabels.sendInn" />
    </Button>
  );

  if (sendInnKunSisteNotat && nextNotat.id !== currentNotat.id) {
    lagreButton = (
      <Button
        className="Button--colored FooterButtons-button FooterButtons-submit"
        disabled={readOnly}
        onClick={() => lagreOgGaVidere(currentNotat.id, nextNotat.id)}
      >
        <FormattedMessage id="formLabels.lagreOgGaVidere" />
        <Icon type="wide" icon="arrow right long" placement="after" />
      </Button>
    );
  }

  const buttonClass = cls({
    "Grid-xl-4-12": notatHasSporsmals,
    "Grid-md-6-12": notatHasSporsmals,
    "Grid-12-12": notatHasSporsmals,
    "FooterButtons-next": notatHasSporsmals,
  });

  return (
    <div className="Page-section Page-section--narrow FooterButtons">
      {notatHasSporsmals ? (
        <div className="Grid-xl-8-12 Grid-md-6-12 Grid-12-12">
          {lagreButton}
          <Button
            className="FooterButtons-button"
            disabled={!hasFeedback || readOnly}
            onClick={showLagreUtkast}
          >
            <FormattedMessage id="formLabels.lagreUtkast" />
          </Button>
          <Button
            className="FooterButtons-button"
            disabled={!hasFeedback || readOnly}
            onClick={showVideresendUtkast}
          >
            <FormattedMessage id="formLabels.videresendUtkast" />
          </Button>
          <Tooltip
            disabled={!hasFeedback || readOnly}
            className="u--inlineBlock"
            message={intl.formatMessage({ id: "formLabels.nyttUtkast" })}
          >
            <LinkButton
              href={routes.hoering(høring.id)}
              aria-label={intl.formatMessage({ id: "formLabels.nyttUtkast" })}
              className="FooterButtons-button"
              disabled={!hasFeedback || readOnly}
            >
              {(props) => (
                <Link
                  {...props}
                  to={props.href!}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Icon type="small" icon="plus" />
                </Link>
              )}
            </LinkButton>
          </Tooltip>
        </div>
      ) : null}
      <div className={buttonClass}>
        {!sendInnKunSisteNotat && nextNotat.id !== currentNotat.id ? (
          <Button
            className="FooterButtons-button"
            onClick={() => navigateToNotat(searchParams, nextNotat.id)}
          >
            <FormattedMessage id="formLabels.nesteNotat" />
            <Icon type="wide" icon="arrow right long" placement="after" />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default LagreOgSendInn;
