import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Icon } from "@utdanningsdirektoratet/icon";

const Obligatorisk = ({
  children,
  className,
  isObligatorisk,
  withLabel,
}: {
  children?: React.ReactNode;
  className?: string;
  isObligatorisk?: boolean;
  withLabel?: boolean;
}) => {
  if (!isObligatorisk) return children || null;

  const obligatoriskClass = cls(
    {
      Obligatorisk: true,
      "Obligatorisk--withLabel": withLabel,
      "Obligatorisk--empty": !children,
    },
    className,
  );

  return (
    <span className={obligatoriskClass}>
      {children}
      {isObligatorisk ? (
        <Icon className="Obligatorisk-icon" icon="asterix" />
      ) : null}
    </span>
  );
};

export default Obligatorisk;
